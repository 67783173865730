
import React, { createContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { getToken } from '../utils/apiUtils';

export const NavigationContext = createContext();;

export const NavigationProvider = ({ children }) => {
  const navigate = useNavigate();

  const redirectHomePageBeforeLogin = () => {
    const uniqueToken = getToken();
    navigate(uniqueToken ? `/?token=${uniqueToken}` : '/');
  };

  // Fix: Add curly braces to define the scope of the return statement
  return (
    <NavigationContext.Provider value={{ redirectHomePageBeforeLogin }}>
      {children}
    </NavigationContext.Provider>
  );
};
