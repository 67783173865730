import React, { createContext, useContext, useState } from 'react';
import { getHeaders, redirectHomePage } from '../utils/apiUtils';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [logoutApiError, setLogoutApiError] = useState(false);
  const [logoutApiErrorMsg, setlogoutApiErrorMsg] = useState("");

  const fetchLogoutApi = async () => {
    try {
      let tempID = sessionStorage.getItem('temp_CallId');
    
    // Example of conditionally changing `tempID`
    if (window.location.pathname === '/feedback') {
      tempID = sessionStorage.getItem('call_id');
    }
      const logoutUrl = `${process.env.REACT_APP_BASE_URL_API}passenger/logout`;

      const logoutResponse = await fetch(logoutUrl, {
        method: 'POST',
        headers: getHeaders(),
        body: JSON.stringify({ 'call_id': tempID }),
      });

      if (!logoutResponse.ok) {
        const errorData = await logoutResponse.json();
        let errorMessage = errorData.error.message;
        setLogoutApiError(true);
        setlogoutApiErrorMsg(errorMessage);
        redirectHomePage();
        return;
      }
      setIsAuthenticated(false);
      const keepItemKey = 'textColorVal';
      const keepItemValue = sessionStorage.getItem(keepItemKey)

      console.log('keepItemKey', keepItemValue)
      sessionStorage.clear();
      if (keepItemValue !== null) {
        sessionStorage.setItem(keepItemKey, keepItemValue);
      }
      redirectHomePage();
    } catch (error) {
      console.error('Logout failed', error);
      redirectHomePage();
    }
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, setIsAuthenticated, logoutApiError, logoutApiErrorMsg, fetchLogoutApi }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
